.hb-separate {
  width: 25px;

  &--horizontal {
    transform: rotate(90deg);
  }

  &--vertical {
    transform: rotate(0deg);
  }
}
