.circle-check.animated {
  animation: circle-check-animation 0.75s ease-out forwards;
}

.fireworks-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  margin-left: -32px;
  margin-top: -3px;
}

.firework {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #20a2d8;
  border-radius: 50%;
  animation: firework-animation 0.75s ease-out forwards;
  transform-origin: center;
}

@keyframes circle-check-animation {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes firework-animation {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  50% {
    transform: scale(1.5)
      translate(calc(50px * var(--angle-x)), calc(50px * var(--angle-y)));
    opacity: 0.8;
  }

  100% {
    transform: scale(0.5)
      translate(calc(100px * var(--angle-x)), calc(100px * var(--angle-y)));
    opacity: 0;
  }
}

.firework:nth-child(1) {
  --angle-x: 1;
  --angle-y: 0;
}

.firework:nth-child(2) {
  --angle-x: 0.71;
  --angle-y: 0.71;
}

.firework:nth-child(3) {
  --angle-x: 0;
  --angle-y: 1;
}

.firework:nth-child(4) {
  --angle-x: -0.71;
  --angle-y: 0.71;
}

.firework:nth-child(5) {
  --angle-x: -1;
  --angle-y: 0;
}

.firework:nth-child(6) {
  --angle-x: -0.71;
  --angle-y: -0.71;
}

.firework:nth-child(7) {
  --angle-x: 0;
  --angle-y: -1;
}

.firework:nth-child(8) {
  --angle-x: 0.71;
  --angle-y: -0.71;
}

.firework:nth-child(9) {
  --angle-x: 0.71;
  --angle-y: -0.71;
}

.firework:nth-child(10) {
  --angle-x: 0;
  --angle-y: -1;
}
