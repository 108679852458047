@import "../Fonts/index.scss";
@import "../Colors/colors.scss";

.authCodeInput {
  width: 3.4rem;
  height: 3.6rem;
  color: $purple;
  font-size: 24px;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid #d6d6d6;
  font-family: $sfProDisplayRegular;

  @media only screen and (max-width: 600px) {
    width: 1rem;
    height: 2rem;
  }
}

.authCodeContainer {
  display: flex;
  justify-content: space-between;
}
