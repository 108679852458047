$NunitoSansItalic: "NunitoSans Italic";
$NunitoSans: "NunitoSans";

@font-face {
  font-family: $NunitoSansItalic;
  src:
    local(NunitoSansItalic),
    local(NunitoSansItalic),
    url("./NunitoSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: $NunitoSans;
  src:
    local(NunitoSans),
    local(NunitoSans),
    url("./NunitoSans.ttf") format("truetype");
}
