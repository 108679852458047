@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

$backgrund: #102939;
$primary: #20a2d8;
$menuItems: 8;
$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);

.homebridge-layout {
  background-color: $backgrund;

  .text-black {
    font-weight: 900;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-center {
    text-align: center;
  }

  .text-start {
    text-align: start;
  }

  h1 {
    color: white;
  }

  .chb-link {
    &__link {
      margin: 0 20px;
    }
  }

  strong {
    font-family: "NunitoSans", sans-serif;
  }

  .strong-700 {
    font-weight: 700;
  }

  .strong-900 {
    font-weight: 900;
  }

  .hb-header {
    @media screen and (max-width: 992px) {
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 72px;
      overflow: hidden;
      transition:
        all 0.3s ease-out,
        background 1s ease-out;
      transition-delay: 0.2s;
      z-index: 9999;

      &.menu-opened {
        position: fixed;
        height: 100dvh;
        transition:
          all 0.3s ease-in,
          background 0.3s ease-in;
        transition-delay: 0.25s;

        .link-list {
          &__mobile {
            .chb-link,
            .hb-separate,
            .animated-arrow-button-container {
              transform: scale(1) translateY(0px);
              opacity: 1;

              @for $i from 1 through $menuItems {
                &:nth-child(#{$i}) {
                  transition-delay: #{0.07 * $i + 0.2}s;
                }
              }
            }
          }
        }
      }

      &.show {
        transform: translateY(0);
        animation: slideDown 0.5s ease-in-out forwards;
      }

      &.hide {
        transform: translateY(-100%);
        animation: slideUp 0.5s ease-in-out forwards;
      }
    }

    .link-list {
      position: relative;
      display: block;
      padding: 0px 48px 0;
      list-style: none;

      @media screen and (min-width: 992px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__desktop {
        @include flex-center;

        @media (max-width: 991px) {
          display: none;
        }
      }

      &__mobile {
        display: none;

        @media screen and (max-width: 991px) {
          margin-top: 2rem;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 1rem;

          .hb-separate {
            &--vertical {
              width: 120px;
            }
          }

          .chb-link,
          .hb-separate,
          .animated-arrow-button-container {
            transform: scale(1.15) translateY(-30px);
            opacity: 0;
            transition:
              transform 0.5s $cubic,
              opacity 0.6s $cubic;

            @for $i from 1 through $menuItems {
              &:nth-child(#{$i}) {
                transition-delay: #{0.56 - ($i * 0.07)}s;
              }
            }
          }
        }
      }
    }
  }

  .hb-footer {
    .link-list {
      position: relative;
      display: block;
      padding: 0px 48px 0;
      list-style: none;

      &__desktop {
        @include flex-center;

        @media (max-width: 992px) {
          display: none;
        }
      }

      &__mobile {
        display: none;

        @media screen and (max-width: 992px) {
          margin-top: 2rem;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 1rem;

          .hb-separate {
            &--vertical {
              width: 120px;
            }
          }
        }
      }
    }
  }

  &__content {
    @media screen and (max-width: 992px) {
      margin-top: 40px;
      padding-top: 30px;
    }
  }

  &__get-started {
    &__content {
      margin-top: 40px;
    }

    @media screen and (max-width: 992px) {
      .hb-header {
        height: 72px;
      }
    }
  }

  &__borrower-tracker {
    h1 {
      color: black;
    }
  }

  &__impersonate_borrower,
  &__impersonate_coborrower {
    background-color: white !important;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
