$backgrund: #102939;
$primary: #20a2d8;

@function shade($color, $percent: 50%) {
  @return mix(black, $color, $percent);
}

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

$btn-width: 250px !default;
$btn-height: 50px !default;

$initialPositionTransition: -25%;
$finalPositionTransition: 100%;
$secondaryInitialPositionTransition: 0%;
$secondaryFinalPositionTransition: -100%;
$trimInitialPosition: 25;

.homebridge-layout {
  .animated-arrow-button-container {
    $btn-color: #20a2d8;
    $btn-color-dark: shade($btn-color, 40%);
    @include size(100%, $btn-height);
    max-width: $btn-width;

    * {
      box-sizing: border-box;
      transition: 0.75s ease-in-out;
    }

    *:before,
    *:after {
      box-sizing: border-box;
      transition: 0.75s ease-in-out;
    }

    .animated-arrow-button {
      background-color: #fff;
      font-size: 18px;
      font-family: "NunitoSans", sans-serif;
      border-radius: 10px;
      cursor: pointer;
      color: #102939;

      border: 1px solid #20a2d8;
      position: relative;
      display: block;
      overflow: hidden;
      @include size(100%, $btn-height);
      max-width: $btn-width;
      text-transform: uppercase;
      z-index: 1;

      p {
        margin: 0;
      }

      &:before,
      &:after,
      span:before,
      span:after {
        content: "";
        @include absolute(0, 0, 0, 0);
        z-index: -1;
      }

      &:before {
        border-left: $btn-height/2 solid transparent;
        transform: translateX($initialPositionTransition);
        border-top: $btn-height/2 solid $btn-color;
        border-bottom: $btn-height/2 solid $btn-color;
        width: 100% + $trimInitialPosition;
      }

      span::before {
        border-left: $btn-height/2 solid transparent;
        transform: translateX($initialPositionTransition + 6);
        border-top: $btn-height/2 solid white;
        border-bottom: $btn-height/2 solid white;
        width: 100% + $trimInitialPosition;
      }

      &:after {
        border-left: $btn-height/2 solid transparent;
        transform: translateX($initialPositionTransition + 8);
        border-top: $btn-height/2 solid $btn-color;
        border-bottom: $btn-height/2 solid $btn-color;
        width: 100% + $trimInitialPosition;
      }

      span:after {
        border-left: $btn-height/2 solid transparent;
        transform: translateX($initialPositionTransition + 8);
        border-top: $btn-height/2 solid white;
        border-bottom: $btn-height/2 solid white;
        width: 100% + $trimInitialPosition;
      }

      &:hover {
        &:before {
          transform: translateX($finalPositionTransition);
        }

        &:after {
          transform: translateX($finalPositionTransition + 8);
        }

        span::before {
          transform: translateX($finalPositionTransition + 6);
        }

        span:after {
          transform: translateX($finalPositionTransition + 8);
        }

        p {
          transform: scale(1.1);
        }
      }
    }

    .secondary-animated-arrow-button {
      background-color: #fff;
      font-size: 18px;
      font-family: "NunitoSans", sans-serif;
      border-radius: 10px;
      cursor: pointer;
      color: #102939;
      border: 1px solid $primary;
      position: relative;
      display: block;
      overflow: hidden;
      @include size(100%, $btn-height);
      max-width: $btn-width;
      text-transform: uppercase;
      z-index: 1;

      p {
        margin: 0;
      }

      &:before,
      &:after,
      span:before,
      span:after {
        content: "";
        @include absolute(0, 0, 0, 0);
        z-index: -1;
      }

      &:before {
        border-right: $btn-height / 2 solid transparent;
        border-top: $btn-height / 2 solid $primary;
        border-bottom: $btn-height / 2 solid $primary;
        transform: translateX($secondaryInitialPositionTransition);
        width: 100% + $trimInitialPosition;
      }

      &:after {
        border-right: $btn-height / 2 solid transparent;
        border-top: $btn-height / 2 solid $primary;
        border-bottom: $btn-height / 2 solid $primary;
        transform: translateX($secondaryInitialPositionTransition - 8);
        width: 100% + $trimInitialPosition;
      }

      span::before {
        border-right: $btn-height / 2 solid transparent;
        border-top: $btn-height / 2 solid white;
        border-bottom: $btn-height / 2 solid white;
        transform: translateX($secondaryInitialPositionTransition - 6);
        width: 100% + $trimInitialPosition;
      }

      span::after {
        border-right: $btn-height / 2 solid transparent;
        border-top: $btn-height / 2 solid white;
        border-bottom: $btn-height / 2 solid white;
        transform: translateX($secondaryInitialPositionTransition - 8);
        width: 100% + $trimInitialPosition;
      }

      &:hover {
        &:before {
          transform: translateX($secondaryFinalPositionTransition);
        }

        &:after {
          transform: translateX($secondaryFinalPositionTransition - 8);
        }

        span::before {
          transform: translateX($secondaryFinalPositionTransition - 6);
        }

        span::after {
          transform: translateX($secondaryFinalPositionTransition - 8);
        }

        p {
          transform: scale(1.1);
        }
      }
    }

    .animated-zoom-button {
      background-color: #20a2d8;
      font-size: 18px;
      font-family: "NunitoSans", sans-serif;
      border-radius: 10px;
      cursor: pointer;
      color: #102939;
      border: 1px solid #20a2d8;
      position: relative;
      display: block;
      overflow: hidden;
      @include size(100%, $btn-height);
      max-width: $btn-width;
      text-transform: uppercase;
      z-index: 1;

      p {
        margin: 0;
      }

      &:before,
      &:after,
      span:before,
      span:after {
        content: "";
        @include absolute(0, 0, 0, 0);
        z-index: -1;
      }

      &:before,
      &:after {
        content: "";
        @include absolute(0, 0, 0, 0);
        border-top: $btn-height/2 solid white;
        border-bottom: $btn-height/2 solid white;
      }

      &:before {
        border-right: $btn-height/2 solid transparent;
        transform: translateX(-100%);
      }

      &:after {
        border-left: $btn-height/2 solid transparent;
        transform: translateX(100%);
      }

      &:hover {
        &:before {
          transform: translateX(-30%);
        }

        &:after {
          transform: translateX(30%);
        }

        p {
          transform: scale(1.1);
        }
      }
    }

    .animated-button {
      background-color: transparent;
      font-size: 18px;
      font-family: "NunitoSans", sans-serif;
      border-radius: 10px;
      cursor: pointer;
      color: white;
      border: 1px solid #20a2d8;
      position: relative;
      display: block;
      overflow: hidden;
      @include size(100%, $btn-height);
      max-width: $btn-width;
      text-transform: uppercase;
      z-index: 1;
      transition: none;

      * {
        box-sizing: border-box;
        transition: none;
      }

      *:before,
      *:after {
        box-sizing: border-box;
        transition: none;
      }

      p {
        margin: 0;
      }

      @media screen and (min-width: 992px) {
        &:hover {
          background-color: #fff;
          color: #102939;
        }
      }

      &--active {
        background-color: #fff;
        color: #102939;
      }

      &--loading {
        cursor: wait;
        &:hover {
          background-color: $backgrund;
          border-color: $btn-color;
        }
      }

      &--disabled {
        cursor: not-allowed;
        border: 1px solid #666;
        background-color: #ccc;
        color: #666;

        &:hover {
          background-color: #ccc;
          color: #666;
        }
      }
    }
  }

  .spinner {
    @include size(20px, 20px);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
