$backgrund: #102939;
$seconaryBackground: #d3e1ea;
$primary: #20a2d8;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fontSize($xs: null, $sm: null, $md: null) {
  @media screen and (min-width: 0px) {
    font-size: $xs + px;
  }

  @media screen and (min-width: 600px) {
    font-size: $sm + px;
  }

  @media screen and (min-width: 900px) {
    font-size: $md + px;
  }
}

.custom__dialog {
  .MuiDialog-container {
    width: 100%;
  }
}

.get-started,
.custom__dialog {
  * {
    box-sizing: border-box;
  }

  max-width: 880px;
  margin: 0 auto;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 2.5rem;
    // padding: 0 2.5rem 2.5rem 2.5rem;
  }

  @media (min-width: 992px) {
    /*  display: flex;
    align-items: center;
    justify-content: center; */
    width: 100%;
    height: 90%;
    margin-top: 20px;

    > *:first-child {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    .full-width {
      max-width: 300px;

      .animated-button {
        max-width: 300px;
      }

      .animated-button-description {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
  }

  padding: 1rem;

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  select {
    margin: 0 auto;
    font-family: "NunitoSans", sans-serif;
    width: 100%;
    padding: 10px;
    border: 2px solid $seconaryBackground;
    background-color: $seconaryBackground;
    border-radius: 5px;
    transition: border 0.3s ease;
    @include fontSize(16, 18, 18);
  }

  input[type="checkbox"] {
    padding: 10px;
    border: 2px solid $seconaryBackground;
    border-radius: 5px;
  }

  &__phoneInput,
  &__dobInput,
  &__ssnInput {
    width: 100%;
    border-radius: 5px;
    @include fontSize(16, 18, 18);
    transition: border 0.3s ease;
    color: $backgrund !important;
  }

  &__arrows {
    color: white;
    align-self: center;
  }

  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="date"]:focus,
  select:focus {
    font-family: "NunitoSans", sans-serif;
    outline: none;
  }

  select option {
    background-color: $backgrund;
    color: #ffffff;
    padding: 15px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  label {
    font-size: 16px;
    font-weight: bold;
    display: block;
  }

  .get-started__label,
  p {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }

  .progress-bar {
    background: $backgrund;
    border: 2.5px solid $primary;
    border-radius: 10px;
    height: 20px;
    position: relative;
    overflow: hidden;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      &--show {
        .circle-check {
          position: absolute;
          right: 0;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .progress-bar .progress {
    height: 100%;
    background: $primary;
    transition: width 0.75s ease;
  }

  button:disabled {
    background: $seconaryBackground;
    cursor: not-allowed;
  }

  button.selected {
    background: #28a745;
  }

  button.danger {
    background: #dc3545;
  }

  .separator {
    border-top: 1px solid #ddd;
    margin: 20px 0;
  }

  .checkbox-group {
    margin-bottom: 15px;

    label {
      margin-left: 10px;
      font-weight: normal;
      color: #555;
    }

    input[type="checkbox"],
    input[type="radio"] {
      margin-right: 5px;
    }
  }

  .subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
  }

  .step-separate {
    width: 100%;
    margin: 50px 0;

    &--separate {
      margin: 30px 0px;
    }
  }

  .animated-arrow-button-container {
    width: 175px;
  }

  .animated-button {
    &--active {
      .offer-number {
        color: $backgrund;
        background-color: $primary;
        border: 2px solid $backgrund;
      }

      .svg-icon {
        path {
          fill: $backgrund;
        }
      }
    }

    &:hover,
    &:focus {
      .offer-number {
        color: $backgrund;
        background-color: $primary;
        border: 2px solid $backgrund;
      }

      @media screen and (min-width: 992px) {
        .svg-icon {
          path {
            fill: $backgrund;
          }
        }
      }
    }
  }

  &__note {
    @include fontSize(14, 16, 16);
    color: #9ab7c6;
    text-align: center;

    span {
      font-weight: 700;
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    font-family: "NunitoSans", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__address {
    &__option {
      cursor: pointer;
      border-bottom: 1px solid $primary;
      padding: 10px;
      color: white;

      &:hover {
        background-color: white;
        color: $backgrund;
      }
    }
  }

  &__checkbox {
    width: 25px;
    height: 25px;
    background-color: $seconaryBackground;
    border: 2px solid $seconaryBackground;
    border-radius: 4px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:checked {
      background-color: $seconaryBackground;
      background-image: url("../../assets/Icons/checkbox.svg");
      background-size: 90% 90%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__loan-request,
  &__verify-email {
    p {
      max-width: 100%;
    }
  }

  &__credit-score {
    .animated-arrow-button-container {
      &--score {
        @media screen and (max-width: 599px) {
          width: 125px;
        }
      }

      .animated-button {
        &--error {
          border: 2px solid red;
        }
      }
    }
  }

  &__offer {
    .animated-arrow-button-container {
      .animated-button {
        p.animated-button-description {
          max-width: 100%;
        }
      }
    }
  }

  &__offer-grid {
    margin: 40px 0;

    .animated-button {
      cursor: pointer;

      &--active,
      &:hover {
        background-color: white;
        color: $backgrund;
        border: 2px solid $primary;

        .offer-number {
          color: $backgrund;
          background-color: $primary;
          border: 2px solid $backgrund;
        }
      }
    }
  }

  &__type-of-ownership {
    @media screen and (min-width: 599px) {
      &__container {
        .animated-arrow-button-container {
          max-width: 350px;
          margin: 0 auto;

          .animated-button {
            max-width: 350px;
          }
        }
      }
    }
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
  appearance: textfield;
}

.custom-typography {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
