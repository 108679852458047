$primary-text: #ffffff;
$secondary-text: #102939;
$tertiary-text: #20a2d8;
$secondary-background: #102939;

.accordion {
  width: 100%;
  &--expanded {
    margin: 0;
    background-color: $primary-text !important;
    color: $secondary-text !important;
    border: 1px solid $secondary-text;

    .accordion__content {
      margin: 0;
    }

    .accordion__icon {
      color: $secondary-text !important;
    }
  }

  &--collapsed {
    background-color: $secondary-background !important;
    color: $primary-text !important;
    border: 1px solid $tertiary-text;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $primary-text !important;
        color: $secondary-text !important;
        border: 1px solid $secondary-text;

        .accordion__icon {
          color: $secondary-text !important;
        }
      }
    }
  }
}

.accordion__icon {
  pointer-events: none;
  color: $primary-text;
}
