@import "./titillium-web/index.scss";
@import "./nunito-sans/index.scss";

$sfProTextBold: "Pro Text Bold";
$sfProTextHeavy: "Pro Text Heavy";
$sfProTextLight: "Pro Text Light";
$sfProTextMedium: "Pro Text Medium";
$sfProDisplayBold: "Pro Display Bold";
$sfProDisplayThin: "Pro Display Thin";
$sfProTextRegular: "Pro Text Regular";
$sfProTextSemibold: "Pro Text Semibold";
$sfProDisplayBlack: "Pro Display Black";
$sfProDisplayLight: "Pro Display Light";
$sfProDisplayHeavy: "Pro Display Heavy";
$sfProDisplayMedium: "Pro Display Medium";
$sfProDisplayRegular: "Pro Display Regular";
$sfProTextBoldItalic: "Pro Text Bold Italic";
$sfProDisplaySemibold: "Pro Display Semibold";
$sfProTextHeavyItalic: "Pro Text Heavy Italic";
$sfProTextLightItalic: "Pro Text Light Italic";
$sfProTextMediumItalic: "Pro Text Medium Italic";
$sfProDisplayUltralight: "Pro Display UltraLight";
$sfProDisplayThinItalic: "Pro Display Thin Italic";
$sfProDisplayBoldItalic: "Pro Display Bold Italic";
$sfProTextRegularItalic: "Pro Text Regular Italic";
$sfProTextSemiboldItalic: "Pro Text Semibold Italic";
$sfProDisplayHeavyItalic: "Pro Display Heavy Italic";
$sfProDisplayBlackItalic: "Pro Display Black Italic";
$sfProDisplayLightItalic: "Pro Display Light Italic";
$sfProDisplayMediumItalic: "Pro Display Medium Italic";
$sfProDisplayRegularmItalic: "Pro Display Regular Italic";
$sfProDisplaySemiboldItalic: "Pro Display Semibold Italic";
$sfProDisplayUltralightItalic: "Pro Display Ultralight Italic";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

strong {
  font-family: $sfProTextBold;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: $sfProDisplayBlack;
  src:
    local(sfProDisplayBlack),
    local(sfProDisplayBlack),
    url("./../Fonts/SF-Pro-Display-Black.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayBlackItalic;
  src:
    local(sfProDisplayBlackItalic),
    local(sfProDisplayBlackItalic),
    url("./../Fonts/SF-Pro-Display-BlackItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayBold;
  src:
    local(sfProDisplayBold),
    local(sfProDisplayBold),
    url("./../Fonts/SF-Pro-Display-Bold.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayBold;
  src:
    local(sfProDisplayBold),
    local(sfProDisplayBold),
    url("./../Fonts/SF-Pro-Display-Bold.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayBoldItalic;
  src:
    local(sfProDisplayBoldItalic),
    local(sfProDisplayBoldItalic),
    url("./../Fonts/SF-Pro-Display-BoldItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayHeavy;
  src:
    local(sfProDisplayHeavy),
    local(sfProDisplayHeavy),
    url("./../Fonts/SF-Pro-Display-Heavy.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayHeavyItalic;
  src:
    local(sfProDisplayHeavyItalic),
    local(sfProDisplayHeavyItalic),
    url("./../Fonts/SF-Pro-Display-HeavyItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayLight;
  src:
    local(sfProDisplayLight),
    local(sfProDisplayLight),
    url("./../Fonts/SF-Pro-Display-Light.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayLightItalic;
  src:
    local(sfProDisplayLightItalic),
    local(sfProDisplayLightItalic),
    url("./../Fonts/SF-Pro-Display-LightItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayMedium;
  src:
    local(sfProDisplayMedium),
    local(sfProDisplayMedium),
    url("./../Fonts/SF-Pro-Display-Medium.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayMediumItalic;
  src:
    local(sfProDisplayMediumItalic),
    local(sfProDisplayMediumItalic),
    url("./../Fonts/SF-Pro-Display-MediumItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayRegular;
  src:
    local(sfProDisplayRegular),
    local(sfProDisplayRegular),
    url("./../Fonts/SF-Pro-Display-Regular.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayRegularmItalic;
  src:
    local(sfProDisplayRegularmItalic),
    local(sfProDisplayRegularmItalic),
    url("./../Fonts/SF-Pro-Display-RegularItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplaySemibold;
  src:
    local(sfProDisplaySemibold),
    local(sfProDisplaySemibold),
    url("./../Fonts/SF-Pro-Display-Semibold.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplaySemiboldItalic;
  src:
    local(sfProDisplaySemiboldItalic),
    local(sfProDisplaySemiboldItalic),
    url("./../Fonts/SF-Pro-Display-SemiboldItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayThin;
  src:
    local(sfProDisplayThin),
    local(sfProDisplayThin),
    url("./../Fonts/SF-Pro-Display-Thin.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayThinItalic;
  src:
    local(sfProDisplayThinItalic),
    local(sfProDisplayThinItalic),
    url("./../Fonts/SF-Pro-Display-ThinItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayUltralight;
  src:
    local(sfProDisplayUltralight),
    local(sfProDisplayUltralight),
    url("./../Fonts/SF-Pro-Display-Ultralight.otf") format("truetype");
}

@font-face {
  font-family: $sfProDisplayUltralightItalic;
  src:
    local(sfProDisplayUltralightItalic),
    local(sfProDisplayUltralightItalic),
    url("./../Fonts/SF-Pro-Display-UltralightItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextBold;
  src:
    local(sfProTextBold),
    local(sfProTextBold),
    url("./../Fonts/SF-Pro-Text-Bold.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextBoldItalic;
  src:
    local(sfProTextBoldItalic),
    local(sfProTextBoldItalic),
    url("./../Fonts/SF-Pro-Text-BoldItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextHeavy;
  src:
    local(sfProTextHeavy),
    local(sfProTextHeavy),
    url("./../Fonts/SF-Pro-Text-Heavy.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextHeavyItalic;
  src:
    local(sfProTextHeavyItalic),
    local(sfProTextHeavyItalic),
    url("./../Fonts/SF-Pro-Text-HeavyItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextLight;
  src:
    local(sfProTextLight),
    local(sfProTextLight),
    url("./../Fonts/SF-Pro-Text-Light.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextLightItalic;
  src:
    local(sfProTextLightItalic),
    local(sfProTextLightItalic),
    url("./../Fonts/SF-Pro-Text-LightItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextMedium;
  src:
    local(sfProTextMedium),
    local(sfProTextMedium),
    url("./../Fonts/SF-Pro-Text-Medium.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextMediumItalic;
  src:
    local(sfProTextMediumItalic),
    local(sfProTextMediumItalic),
    url("./../Fonts/SF-Pro-Text-MediumItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextRegular;
  src:
    local(sfProTextRegular),
    local(sfProTextRegular),
    url("./../Fonts/SF-Pro-Text-Regular.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextRegularItalic;
  src:
    local(sfProTextRegularItalic),
    local(sfProTextRegularItalic),
    url("./../Fonts/SF-Pro-Text-RegularItalic.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextSemibold;
  src:
    local(sfProTextSemibold),
    local(sfProTextSemibold),
    url("./../Fonts/SF-Pro-Text-Semibold.otf") format("truetype");
}

@font-face {
  font-family: $sfProTextSemiboldItalic;
  src:
    local(sfProTextSemiboldItalic),
    local(sfProTextSemiboldItalic),
    url("./../Fonts/SF-Pro-Text-SemiboldItalic.otf") format("truetype");
}
