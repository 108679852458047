$titilliumBlack: "Titillium Black";
$titilliumBold: "Titillium Bold";
$titilliumBoldItalic: "Titillium Bold Italic";
$titilliumExtraLight: "Titillium Extra Light";
$titilliumExtraLightItalic: "Titillium Extra Light Italic";
$titilliumItalic: "Titillium Italic";
$titilliumLight: "Titillium Light";
$titilliumLightItalic: "Titillium Light Italic";
$titilliumRegular: "Titillium Regular";
$titilliumSemiBold: "Titillium Semi Bold";
$titilliumSemiBoldItalic: "Titillium Semi Bold Italic";

@font-face {
  font-family: $titilliumBlack;
  src:
    local(titilliumBlack),
    local(titilliumBlack),
    url("./TitilliumWeb-Black.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumBold;
  src:
    local(titilliumBold),
    local(titilliumBold),
    url("./TitilliumWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumBoldItalic;
  src:
    local(titilliumBoldItalic),
    local(titilliumBoldItalic),
    url("./TitilliumWeb-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumExtraLight;
  src:
    local(titilliumExtraLight),
    local(titilliumExtraLight),
    url("./TitilliumWeb-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumExtraLightItalic;
  src:
    local(titilliumExtraLightItalic),
    local(titilliumExtraLightItalic),
    url("./TitilliumWeb-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumItalic;
  src:
    local(titilliumItalic),
    local(titilliumItalic),
    url("./TitilliumWeb-Italic.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumLight;
  src:
    local(titilliumLight),
    local(titilliumLight),
    url("./TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumLightItalic;
  src:
    local(titilliumLightItalic),
    local(titilliumLightItalic),
    url("./TitilliumWeb-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumRegular;
  src:
    local(titilliumRegular),
    local(titilliumRegular),
    url("./TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumSemiBold;
  src:
    local(titilliumSemiBold),
    local(titilliumSemiBold),
    url("./TitilliumWeb-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: $titilliumSemiBoldItalic;
  src:
    local(titilliumSemiBoldItalic),
    local(titilliumSemiBoldItalic),
    url("./TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
}
