@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.home__title {
  font-size: 4rem;
  // color: #4C5273;
  font-family: "Montserrat", sans-serif !important;
}

:root {
  --text: hsl(0, 0%, 99%);
  --textDim: hsl(0, 0%, 60%);
  --background: hsl(0, 0%, 7%);
  --primary: hsl(155, 100%, 65%);
  --primaryBg: hsla(155, 100%, 65%, 1%);
  --primaryHi: hsla(155, 100%, 75%, 25%);
  --primaryFg: hsl(155, 100%, 85%);
  --secondary: hsl(156, 51%, 14%);
  --secondaryFg: hsl(156, 51%, 75%);
  --secondaryBg: hsla(156, 51%, 14%, 5%);
  --secondaryHi: hsla(156, 51%, 30%, 50%);
  --accent: hsl(155, 100%, 94%);
  --accentBg: hsla(155, 100%, 94%, 1%);
  --accentHi: hsla(155, 100%, 100%, 25%);
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  height: 50px;
  width: 160px;
}

.buttongeneral {
  height: 50px;
  width: 160px;
  clip-path: path(
    "M 0 25 C 0 -5, -5 0, 80 0 S 160 -5, 160 25, 165 50 80 50, 0 55, 0 25"
  );
  border: none;
  border-radius: 13px;
  box-shadow: 0px -3px 15px 0px #484876 inset;
  color: white;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateY(0px);
  transition: all 0.2s ease;
  font-weight: bold;
  cursor: pointer;
}

.buttongeneral:hover {
  transform: translateY(-3px);
}

.home__titlecontainer {
  position: relative;
  &__img1 {
    position: absolute;
    right: 0%;
    top: -40%;
    animation: floatdown 6.5s ease-in-out infinite;
  }
  &__img2 {
    position: absolute;
    left: -10%;
    bottom: -40%;
    animation: float 6.5s ease-in-out infinite;
  }
}
.header::before {
  --line: #d7d7f0;
  --size: 120px;
  content: "";
  height: 100vh;
  width: 100vw;
  position: absolute;
  background:
    linear-gradient(90deg, var(--line) 1px, transparent 1px var(--size)) 50% 50% /
      var(--size) var(--size),
    linear-gradient(var(--line) 1px, transparent 1px var(--size)) 50% 50% /
      var(--size) var(--size);
  mask: linear-gradient(-15deg, transparent 30%, white);
  top: 0;
  z-index: 0;
  transform: translate3d(0, 0, -100vmin);
  opacity: 0.4;
}
.header::after {
  background-color: #f5f5f5;
  content: "";
  width: 100vw;
  height: 80vh;
  position: absolute;
  z-index: -1;
}
.header__principalimage {
  position: absolute;
  max-height: 550px;
  max-width: 550px;
  right: 0%;
  -webkit-box-reflect: below -41% linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.33));
  @media only screen and (max-width: 920px) {
    display: none;
  }
  @media only screen and (max-width: 1050px) {
    max-height: 450px;
    max-width: 450px;
  }
}
.header {
  h3 {
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  }
}

/// animations for the header //
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes floatdown {
  0% {
    transform: translatey(-20px);
  }
  50% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(-20px);
  }
}

// .benefits__body{
//  animation:fade linear both;
//  animation-timeline:view();
//  animation-range: entry 50% cover 50%;

// }
// @keyframes fade {
//   from{
//   opacity:0.6
//   }
//   to{
//   opacity:1
//   }
// }
