@use "sass:math";
@font-face {
  font-family: "Basis Grotesque Arabic Pro";
  src: url("../../assets/Fonts/BasisGrotesqueArabicPro-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Basis Grotesque Arabic Pro";
  src: url("../../assets/Fonts/BasisGrotesqueArabicPro-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Basis Grotesque Arabic Pro";
  src: url("../../assets/Fonts/BasisGrotesqueArabicPro-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Basis Grotesque Arabic Pro";
  src: url("../../assets/Fonts/BasisGrotesqueArabicPro-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;

$green-100: #dcfce7;
$green-500: #10b981;
$green-600: #10b981;
$green-800: #15803d;

$red-100: #fee2e2;
$red-800: #b91c1c;

$yellow-100: #fef3c7;
$yellow-800: #92400e;

$indigo-600: #4f46e5;
$indigo-700: #4338ca;

body {
  font-family:
    "Basis Grotesque Arabic Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

@mixin badge($bg-color, $text-color, $border-color) {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: $bg-color;
  color: $text-color;
  border: 1px solid $border-color;
  gap: 3px;
}

.loans__container {
  min-height: 80dvh;
  overflow: hidden;

  .loans__title {
    padding-top: 3vh;
    align-items: center;
    @media (max-width: 600px) {
      margin: 0 auto;
      width: 95%;
    }

    h4 {
      background-size: 200% 207%;
      background-image: linear-gradient(
        rgb(72, 72, 118),
        #3d3d63,
        rgb(0 0 0 / 79%),
        #fff,
        #ffffff
      );
      color: transparent;
      display: inline-block;
      background-clip: text;
      font-size: 2.6rem;
    }
  }
}

#right_panel {
  position: relative;
  width: 1000px;
  max-width: 100%;
  filter: drop-shadow(0px 0px 1px rgba(178, 178, 255, 0.5294117647));
}

.wrap-table {
  container-type: inline-size;
  container-name: table;
  position: relative;
  z-index: 10000000;
  border: 2px solid #ffffff;
  border-radius: 1rem;
  overflow-x: auto;
  background: #ebedf7;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
  @media (max-width: 600px) {
    width: 95%;
    margin: 0 auto;
  }

  > table {
    width: 100%;
    position: relative;
    z-index: 20000000;
    border: 5px solid #ffffff;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 0px 19px #ffffff;
    // background: #a4a4f940;
    border-collapse: collapse;
    font-size: 1rem;
  }

  th {
    font-weight: 700;
    color: #747479;
    text-align: left;
    padding: 0.75rem;
    background: #f3f3f7;
    border-bottom: 1px solid $gray-200;
  }
}

td {
  padding: 0.75rem;
  border-bottom: 1px solid $gray-200;
  background: white;
}

.user {
  &__info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  &__photo {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 50%;
  }

  &__name {
    font-weight: 600;
    color: $gray-900;
  }

  &__email {
    font-size: 0.75rem;
    color: $gray-600;
  }

  &__badge {
    &--funded {
      @include badge($green-100, $green-800, $green-800);
    }
    &--archived {
      @include badge($red-100, $red-800, $red-800);
      filter: grayscale(1);
    }
    &--active {
      @include badge($yellow-100, $yellow-800, $yellow-800);
    }
  }

  &__progress {
    width: 6rem;
    background-color: $gray-200;
    border-radius: 9999px;
    height: 0.5rem;
    overflow: hidden;

    &-bar {
      height: 100%;
      border-radius: 9999px;
      background-color: $green-500;
    }
  }
}

.view__button {
  all: unset;
  display: inline-block;
  cursor: pointer;
  color: #3d3d63;
  transition: color 0.2s;
  font-weight: 700;

  &:hover {
    color: #4c5273;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}

.button__myloans {
  font-family:
    "Basis Grotesque Arabic Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  background-color: #6767aa;
  color: #fff;
  width: 8.5em;
  height: 2.9em;
  border: whitesmoke 0.2em solid;
  border-radius: 11px;
  text-align: right;
  transition: all 0.3s ease-out;
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;

  &:hover {
    .icon {
      transform: translateX(5px);
      opacity: 1;
    }
  }
}

.icon {
  width: 1.6em;
  position: relative;
  display: flex;
  transition: all 0.3s ease-out;
  opacity: 0;
}

.text {
  margin-left: 15px;
}

#btn_resize {
  cursor: ew-resize;
  width: 0.875rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 0.125rem;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: none;
  padding: 0;

  span {
    background: $gray-400;
    height: 2rem;
    width: 0.25rem;
    border-radius: 9999px;
  }
}

.hero-background {
  transform-style: preserve-3d;
  background-image: radial-gradient(
    circle closest-side,
    rgb(207 196 249 / 35%),
    rgb(255 255 255 / 0%)
  );
  height: 650px;
  position: absolute;
  inset: 50% 75% auto;
  pointer-events: none;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
}

.loans__subtitle {
  @media (max-width: 600px) {
    margin: 0 auto;
    width: 95%;
  }

  h5 {
    font-size: 1.12rem;
    font-weight: 500;
    color: rgb(138 138 169);
    line-height: 2;
    margin-left: 5px;
    padding-bottom: 1vh;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
}
.ray {
  width: 100%;
  height: 50px;
  border-radius: 100px;
  position: absolute;
  background-color: #6767aa;
  opacity: 0.2;
  box-shadow: 0 0 50px #fff;
  filter: blur(10px);
  transform-origin: 7%;
  top: 0%;
  left: 0;
  transform: rotate(17deg);
  z-index: 3;
  mix-blend-mode: normal;
}
@container table (max-width: 500px) {
  table {
    display: block;

    tbody,
    tr {
      display: block;
      width: 100%;
      @media (max-width: 600px) {
        display: block;
        width: 98%;
      }
    }

    td {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: none;
      padding: 0.75rem;
      width: 100%;

      &::before {
        content: attr(data-label);
        font-weight: 700;
        color: #374151;
        font-size: 14px;
      }
    }

    td:first-child {
      background: #f9fafb;
    }

    th {
      display: none;
    }
  }

  .button__myloans {
    width: auto;
    padding: 0 1em;
  }
}

@container table (min-width: 400px) {
  .sticky-col {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .sticky-col-right {
    position: sticky;
    right: 0;
    z-index: 1;
  }
}

.container__icons {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  background-color: var(--palette-primary-main);
  border-radius: 30px;
  padding: 0;
  width: -moz-fit-content;
  width: fit-content;

  .icon-content {
    margin: 0 5px;
    position: relative;
    font-weight: bolder;
    cursor: pointer;

    .tooltip {
      position: absolute;
      top: -20px;
      left: 10%;
      transform: translateX(-50%);
      background-color: #fff;
      color: #000;
      padding: 6px 10px;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      font-size: 11px;
      transition: all 0.3s ease;
      font-weight: bolder;
      text-wrap: nowrap;
      letter-spacing: -0.4px;
      &:hover {
        opacity: 1;
        visibility: visible;
        top: -30px;
      }

      @media screen and (min-width: 300px) {
        left: -10px;
      }
    }

    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 3px 2px 45px 0px rgba(0, 0, 0, 0.13);
      }

      svg {
        width: 25px;
        height: 25px;
        fill: #fff;
      }

      &[data-social="spotify"]:hover {
        color: #1db954;
      }

      &[data-social="pinterest"]:hover {
        color: #bd081c;
      }

      &[data-social="dribbble"]:hover {
        color: #ea4c89;
      }

      &[data-social="telegram"]:hover {
        color: #0088cc;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
        top: -30px;
      }
    }
  }
}
.container__icons__pl {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  background-color: var(--palette-primary-main);
  border-radius: 30px;
  padding: 0;
  width: -moz-fit-content;
  width: fit-content;

  .icon-content {
    margin: 0 5px;
    position: relative;
    font-weight: bolder;
    cursor: pointer;

    .tooltip {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      color: #000;
      padding: 6px 10px;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      font-size: 12px;
      transition: all 0.3s ease;
      font-weight: bolder;
      text-wrap: nowrap;

      &:hover {
        opacity: 1;
        visibility: visible;
        top: -30px;
        @media screen and (min-width: 300px) {
          left: -20px;
          transform: none;
        }
      }
    }

    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 3px 2px 45px 0px rgba(0, 0, 0, 0.12);
      }

      svg {
        width: 25px;
        height: 25px;
        fill: #fff;
      }

      &[data-social="spotify"]:hover {
        color: #1db954;
      }

      &[data-social="pinterest"]:hover {
        color: #bd081c;
      }

      &[data-social="dribbble"]:hover {
        color: #ea4c89;
      }

      &[data-social="telegram"]:hover {
        color: #0088cc;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
        top: -100px;
      }
    }
  }
}
.modal-backdrop {
  background-color: rgba(31, 41, 55, 0.2);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(2px);
  position: fixed;
  inset: 0;
  z-index: 100000000000000000000000;
  display: grid;
  place-items: center;
  overflow-y: scroll;
  cursor: pointer;
  padding: 2rem;
  position: absolute;
  height: 100vh;
  width: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  @media (min-width: 600px) {
    width: 100%;
  }
}

.modal-content {
  background-color: white;
  color: black;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 30rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  transform: scale(1) rotate(0deg) translateZ(0px);
  background-image: radial-gradient(#3d3d6317 1px, transparent 1px);
  background-position: 50% 50%;
  background-size: 1.1rem 1.1rem;
  svg {
    position: absolute;
    top: 5%;
    right: 4%;
    color: #3d3d63;
    transform: scale(1.3);
    transition: all 0.2s ease-out;
    &:hover {
      transform: scale(1.4);
    }
  }
}

.modal-inner {
  position: relative;
  z-index: 10;
  text-align: center; // text-center
}

.modal-icon {
  background: white;
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  color: #4f46e5; // text-indigo-600
  display: grid;
  place-items: center; // grid place-items-center
  font-size: 2rem; // text-3xl
}

.modal-title {
  font-size: 1.875rem; // text-3xl
  font-weight: bold; // font-bold
  margin-bottom: 0.5rem; // mb-2
}

.modal-description {
  margin-bottom: 1.5rem; // mb-6
}

.modal-buttons {
  display: flex;
  gap: 0.5rem; // gap-2
}

.policy-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.policy-item {
  &.full-width {
    grid-column: 1 / -1;
  }

  label {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0;
  }
}

.update__button {
  width: 100%;
  padding: 12px;
  background-color: #6767aa;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: 600;

  &:hover {
    background-color: #484876;
  }
}
.update__buttonpl {
  width: 100%;
  padding: 12px;
  background-color: var(--palette-primary-main);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: 600;

  &:hover {
    background-color: var(--palette-primary-main);
    filter: opacity(0.97);
  }
}

@media (max-width: 600px) {
  .policy-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .policy-card {
    padding: 16px;
  }
}
