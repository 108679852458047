.custom-file-upload {
  position: relative;
  width: 400px;
  height: 40px;
  border: 1px solid #d8d6de;
  border-radius: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
  color: #d8d6de;
  margin-bottom: 16px;
}

.file-name {
  flex: 1;
  padding-left: 12px;
  font-weight: 200;
  color: #b9b9c3;
}

.browse-btn {
  width: 120px;
  height: 100%;
  background-color: white;
  border: none;
  border-left: 1px solid #d8d6de;
  color: #5e5873;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.file-input {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
